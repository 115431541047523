<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>学员评价</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
      >
        <el-form-item label="搜索">
          <el-input v-model="key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selList()">查询</el-button>
          <el-button
            type="success"
            @click="
              dialogVisible = true;
              form = { isEnable: true };
              cateList = [];
            "
            >新增</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>学员评价</span>
        </div>
        <!-- 列表定义 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
          :tree-props="{ children: 'children' }"
        >
          <el-table-column prop="nickName" label="真实姓名"></el-table-column>
          <el-table-column prop="japanese" label="日语课程"></el-table-column>
          <el-table-column prop="className" label="所在班级"></el-table-column>

          <!-- <el-table-column prop="summary" label="简介"></el-table-column> -->
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="createTime" label="添加时间"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-edit"
                    @click="editClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="warning"
                    size="mini"
                    icon="el-icon-delete"
                    @click="delClick(scope.row)"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- 操作对话框 -->
    <el-dialog
      :title="form.id > 0 ? '编辑学员评价' : '添加学员评价'"
      :visible.sync="dialogVisible"
      @opened="dialogOpened()"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-form-item label="学生姓名" prop="nickName" :rules="[{ required: true, message: '不能为空' }]">
          <el-input
            v-model="form.nickName"
            placeholder="请输入学生姓名"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="学生头像"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <div id="uploadBtn1" class="uploader uploader-warning"></div>
          <img
            id="relativeName"
            :src="domain + form.imgUrl"
            style="height: 40px; position: relative; left: 10px; top: 20px"
            v-if="form.imgUrl"
          />
              <span
                class="imgtext"
                title
                type="info"
                style="position: relative; left: 0px; top: 1px"
                >*图片尺寸：推荐150*150</span
              >
        </el-form-item>

        <el-form-item label="日语课程" prop="japanese" :rules="[{required:true,message:'不能为空'}]">
          <el-select v-model="form.japanese" placeholder="请输入语言课程" clearable>
            <el-option
            v-for="item in baselist"
            :key="item.id"
            :value="item.name"
            :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="所在班级"
          prop="className"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-input
            v-model="form.className"
            placeholder="请输入学生班级"
          ></el-input>
        </el-form-item>

        <el-form-item label="评价标签" prop="lableList">
          <el-input
            v-model="form.lableList"
            placeholder="请输入评价标签，逗号隔开"
          ></el-input>
        </el-form-item>

        <el-form-item label="简介" prop="summary">
       <el-input
                type="textarea"
                :autosize="{ minRows: 8 }"
                v-model="form.summary"
                placeholder="请输入简介,多条之间使用换行隔开"
              ></el-input>
        </el-form-item>

        <el-form-item label="排序字段" prop="sort">
          <el-input
            v-model.number="form.sort"
            placeholder="请输入排序字段"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
export default {
  data() {
    return {
      domain: url.getDomain(),
      key: "",
      query: {}, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      id: 0,
      baselist:[],//语言基础
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    // 获取语言基础
    getLanguBaseList: function () {
      var _this = this;
      var link = url.getVideo("GetParam_Japanese");
      $.get(link, {}, (data) => {
        _this.baselist = data.baseList;
      });
    },
    // 查询列表
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      params.key = _this.key;
      var link = url.getVideo("GetList_JapaneseJudge");

      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //搜索查询
    selList: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    //上传文件
    dialogOpened: function () {
      var _this = this;

      // 上传图片
      if ($("#uploadBtn1 input").length == 0) {
        $("#uploadBtn1").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          type: "dialog",
          coverParams: {
            targetWidth: 150,
            targetHeight: 150,
          },
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var src = url.getDomain() + data.relativeName;
            _this.$set(_this.form, "imgUrl", data.relativeName);
          },
        });
      }
    },
    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getVideo("Save_JapaneseJudge");
      var info = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台添加
          //添加或者修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //编辑
    editClick: function (row) {
      if(row.lableList){
        row.laleList=row.lableList.split(",");
      }
      if(row.lableList){
        row.laleList=row.lableList.split("，");
      }
      this.form = Object.assign({}, row);
      this.dialogVisible = true;
    },
    //删除
    delClick: function (row) {
      var _this = this;
      var link = url.getVideo("Delete_JapaneseJudge"); //获取请求地址
      var id = row.id; //获取对象id

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted() {
    // 获取语言基础
    this.getLanguBaseList();
    // 列表
    this.getList();
  },
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
